import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql, Link, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../tailwind.config'
import { SocialMediaLinks } from '../../utils'

const tw = resolveConfig(tailwindConfig)


const Faqs = ({ pageContext, location }) => {
    const page = pageContext.page

    const data = useStaticQuery(graphql`
        fragment iconImageSharp on File {
            childImageSharp {
                gatsbyImageData(width: 122)
            }
        }
        query {
            check2: file(name: {eq: "faqs-check-2"}) {
                publicURL
                absolutePath
            }
            check3: file(name: {eq: "faqs-check-3"}) {
                publicURL
                absolutePath
            }
            faq1: file(name: {eq: "faq-back-list-1"}) {
                ...iconImageSharp
            }
            faq2: file(name: {eq: "faq-back-list-2"}) {
                ...iconImageSharp
            }
            faq3: file(name: {eq: "faq-back-list-3"}) {
                ...iconImageSharp
            }
            faq4: file(name: {eq: "faq-back-list-4"}) {
                ...iconImageSharp
            }
            faq5: file(name: {eq: "faq-back-list-5"}) {
                ...iconImageSharp
            }
            faq6: file(name: {eq: "faq-back-list-6"}) {
                ...iconImageSharp
            }
            faq7: file(name: {eq: "faq-back-list-7"}) {
                ...iconImageSharp
            }
            faq8: file(name: {eq: "faq-back-list-8"}) {
                ...iconImageSharp
            }
            faq9: file(name: {eq: "faq-back-list-9"}) {
                ...iconImageSharp
            }
            faq10: file(name: {eq: "faq-back-list-10"}) {
                ...iconImageSharp
            }
            faq11: file(name: {eq: "faq-back-list-11"}) {
                ...iconImageSharp
            }
            condoms: file(name: {eq: "faq-list-condom-icn"}) {
                ...iconImageSharp
            }
            injectables: file(name: {eq: "faq-list-injectables-icn"}) {
                ...iconImageSharp
            }
            iuds: file(name: {eq: "faq-list-iud-icn"}) {
                ...iconImageSharp
            }
            pills: file(name: {eq: "faq-list-pills-icn"}) {
                ...iconImageSharp
            }

            favicon: file(relativePath: {eq: "logo-w431.png"}) {
                id
                childImageSharp {
                    gatsbyImageData(
                        width: 431
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                    original {
                        src
                    }
                }
            }
        }
    `)

    return (
        <Layout>
            <Seo title={page.title} meta={[
                { property:'og:url', content:location.href }
            ].concat(SocialMediaLinks(data.favicon?.childImageSharp.original.src))}/>

            <div className="relative pt-10 text-center md:pt-20 lg:pt-32 bg-pattensblue ">
                <div className="my-container">
                    <h1 className="relative z-40 inline-block py-4 mx-auto">
                        <span className="sr-only">Frequently Asked Questions</span>
                        <StaticImage 
                            src="../../images/faq_header_image.png" 
                            alt="TARA! ALAMIN ANG TAMA. MAKINABANG SA MGA BENEPISYO NG FAMILY PLANNING" />
                    </h1>
                </div>
            </div>

            <section className="bg-pattensblue">
                <div className="my-container">
                    <Box1 className="bg-pattensblue" >
                        <p className="relative z-10 py-5 mx-5 text-sm tracking-wider text-justify md:text-lg lg:text-2xl">
                            Ang <Link to="/our-advocacy/" ><strong>Family Planning</strong></Link> ay ang pagpaplano ng minimithing BILANG at wastong PAG-AAGWAT ng mga anak. Ito ay kadalasang nakakamit sa pamamagitan ng paggamit ng mga moderno, mabisa at epektibong pamamaraan ng contraception.
                        </p>
                    </Box1>
                </div>
            </section>

            <section className="pt-4 bg-pattensblue">
                <div className="pb-5 my-container" style={{paddingLeft:0, paddingRight:0, backgroundColor:'#c8e1f5'}}>
                    
                    <FAQBoxHeader bgColor="#008bd2" textShadow="#005da2" bottomColor="#00336c">
                        <h3 className="text-xl tracking-wider md:text-4xl text-header">Mga Benepisyo ng Family Planning</h3>
                    </FAQBoxHeader>
                    
                    <ResponsiveIcon 
                        className="float-right m-5 rounded-full lg:mr-20" 
                        style={{ shapeOutside:'circle(80px at center)' }}>
                        <StaticImage 
                            src="../../images/faq-header-fam-pic.png" 
                            alt="Family Pic"/>
                    </ResponsiveIcon>
                    <div className="pt-5" />
                    <ListContainer1 key={1} title="Ina" listIconUrl={data.check2.publicURL} listTexts={[
                        "Mabilis mababawi ang lakas pagkatapos ng pagbubuntis at panganganak.",
                        "May panahon para sa pamilya (anak, asawa at sa kanyang sarili).",
                        "Mas maayos maaalagaan at mapapalaki ang mga anak.",
                        "Mas maaalagaan ang kanyang sariling kalusugan at kapakanan."
                    ]}/>
                    <ListContainer1 key={2} title="Ama" listIconUrl={data.check2.publicURL} listTexts={[
                        "Makakaipon para sa pag-aaral at iba pang pangangailangan ng mga anak.",
                        "Mas may panahon para sa pamilya, at magagampanan ang mga tungkulin."
                    ]}/>
                    <ListContainer1 key={3} title="Sanggol at mga anak" listIconUrl={data.check2.publicURL} listTexts={[
                        "Mas mabibigyan ng sapat na panahon sa pagpapasuso, pagpapabakuna, wastong nutrisyon at pangangalaga sa ngipin.",
                        "Mabibigyan ng sapat na edukasyon, pagmamahal, pag-aaruga at kalinga, at paghubog ng wastong kaisipan."
                    ]}/>
                    <ListContainer1 key={4} title="Buong Pamilya" listIconUrl={data.check2.publicURL} listTexts={[
                        "Mas mababantayan ang kalusugan.",
                        "Mas makakamit ang mas maginhawang kabuhayan dahil matutugunan ang mga pangangailangan."
                    ]}/>

                </div>
            </section>

            <section className="bg-pattensblue">
                <div className="my-container" style={{paddingLeft:0, paddingRight:0, backgroundColor:'#fef7e4'}}>
                    <FAQBoxHeader bgColor="#dd661d" textShadow="#ab4e10" bottomColor="#ab4e10">
                        <h3 className="text-xl tracking-wider md:text-4xl text-header">Mga Modernong Pamamaraan ng Family Planning</h3>
                    </FAQBoxHeader>                    

                    <div className="flex flex-col flex-wrap py-5" >

                        <ListContainer2 key={1} 
                            title="Condom" 
                            listIconUrl={data.check3.publicURL} 
                            image={ getImage(data.condoms.childImageSharp) } 
                            listTexts={[
                            {
                                title: "",
                                texts: [
                                    "98% mabisa kung tama ang paggamit, pagsuot at pagtanggal nito.",
                                    "Isinusuot ng lalaki sa kanyang matigas na ari bago tuluyang makipagtalik.",
                                    "Nakatutulong para makaiwas sa mga sakit na maaaring makuha sa pakikipagtalik tulad ng HIV at mga Sexually Transmitted Infections (STI)."
                                ]
                            }
                        ]}/>                    
                        
                        <ListContainer2 key={2} 
                            title="Injectables" 
                            listIconUrl={data.check3.publicURL} 
                            image={ getImage(data.injectables.childImageSharp) } 
                            listTexts={[
                            {
                                title:"Combined Injectable Contraceptive", 
                                texts:[
                                    "99.95% mabisa sa tamang paggamit.",
                                    "Isang iniksyon lang bawat isang (1) buwan. Nagiging regular ang pagdating ng regla.",
                                    "Pwedeng itigil anumang oras na gusto muling magka-anak."
                                ]
                            },
                            {
                                title:"Progestin Only Injectable", 
                                texts:[
                                    "99.7% mabisa sa tamang paggamit.",
                                    "Isang iniksyon lang kada tatlong (3) buwan.",
                                    "Ligtas gamitin ng mga nagpapasusong ina.",
                                    "Hindi nakakaapekto sa pagnanais sa pakikipagtalik."
                                ]
                            }
                        ]}/>

                        <ListContainer2 key={3} 
                            title="Pills" 
                            listIconUrl={data.check3.publicURL} 
                            image={ getImage(data.pills.childImageSharp) } 
                            listTexts={[
                            {
                                title: "",
                                texts: [
                                    "Mabisa at hindi sagabal sa pakikipagtalik.",
                                    "Ito ay iniinom ng babae araw-araw sa parehong oras na walang mintis.",
                                    "Pwedeng itigil inumin anumang oras na gusto muling magka-anak."
                                ]
                            }
                        ]}/>

                        <ListContainer2 key={4} 
                            title="Dalawang uri ng Pills:" 
                            listIconUrl={data.check3.publicURL} 
                            listTexts={[
                            {
                                title: "Combined Oral Contraceptives (COCs)",
                                texts: [
                                    "99.7% mabisa kung tama ang pag-inom nito.",
                                    "Pinipigilan ang obulasyon (o ang paglabas ng hinog na itlog mula sa obaryo ng babae).",
                                    "May sangkap na “hormones” (estrogen at progestin) na pumipigil sa pagbubuntis ng babae.",
                                    "Hindi inirerekomenda sa mga nagpapasusong ina."
                                ]
                            },
                            {
                                title: "Progestin Only Pills (POPs)",
                                texts: [
                                    "99.5% mabisa kung tama ang pag-inom nito.",
                                    "May sangkap na progestin hormone na pumipigil sa obulasyon ng babae.",
                                    "Ito ay angkop sa mga nagpapasuso, dahil sa hindi nababawasan ang daloy at dami ng gatas ng nagpapasusong ina."
                                ]
                            }
                        ]}/>

                        <ListContainer2 key={5} 
                            title="Intrauterine Device (IUD)" 
                            listIconUrl={data.check3.publicURL} 
                            image={ getImage(data.iuds.childImageSharp) } 
                            listTexts={[
                            {
                                title: "",
                                texts: [
                                    "99.4% mabisa",
                                    "Hanggang 10 taon ang bisa",
                                    "Maliit at malambot na plastic device na inilalagay ng trained health care provider sa matris ng babae.",
                                    "Ligtas gamitin ng mga nagpapasusong ina.",
                                    "Hindi nakakaapekto sa pagnanais sa pakikipagtalik."
                                ]
                            }
                        ]}/>
                    </div>
                </div>
            </section>

            <section className="bg-pattensblue">
                <div className="relative z-10 my-container" style={{paddingLeft:0, paddingRight:0, backgroundColor:'#e0fcff'}}>
                    
                    <FAQBoxHeader bgColor="#007c7d" textShadow="#00484e" bottomColor="#00484e">
                        <h3 className="text-xl tracking-wider md:text-4xl text-header">Tamang Kaalaman at Sagot sa mga MALING AKALA!</h3>
                    </FAQBoxHeader>

                    <ol className="grid items-stretch grid-cols-1 gap-0 py-5 place-items-center sm:grid-cols-2 text-md md:text-2xl" 
                        style={{ listStyleType:'decimal', listStylePosition:'inside', counterReset:'list' }}>
                        <ListItem>
                            <Image image={ getImage(data.faq1.childImageSharp) } altText="Fat Thumbnail" />
                            <ListItemHeader>Nakakataba ang Pills</ListItemHeader>
                            <UlList listStyleImage={data.check2.publicURL}>
                                <li><strong>Hindi.</strong> May mga pills na hiyang sa iyo at makatutulong na panatilihin ang kasalukuyang timbang o di kaya ay magbigay ng karagdagang benepisyo sa pagganda ng kutis.</li>
                                <li>Maraming ibang dahilan kung bakit tumataba, katulad ng pagbagal ng metabolism habang nagkakaedad o di kaya’y paglakas ng gana sa pagkain.</li>
                            </UlList>
                        </ListItem>
                        <ListItem>
                            <Image image={ getImage(data.faq2.childImageSharp) } altText="Pills Thumbnail" />
                            <ListItemHeader>Naiipon ba sa loob ng puson ang Pills?</ListItemHeader>
                            <p><strong>Hindi.</strong> Inilalabas ito araw-araw sa pamamagitan ng pagdumi at pag-ihi. Ito ang dahilan kung bakit kinakailangang araw-araw itong inumin upang hindi mawala ang bisa sa katawan.</p>
                        </ListItem>
                        <ListItem>
                            <Image image={ getImage(data.faq3.childImageSharp) } altText="Pregnant Thumbnail" />
                            <ListItemHeader>Nakakaapekto ba ang Pills sa muling kakayahang magbuntis?</ListItemHeader>
                            <p>Ayon sa mga eksperto, wala. Ang kakayahang magbuntis ay karaniwang bumabalik sa loob ng 1-6 na buwan pagkatapos tumigil mag-Pills.</p>
                        </ListItem>
                        <ListItem>
                            <Image image={ getImage(data.faq4.childImageSharp) } altText="Woman holding a pills" />
                            <ListItemHeader>Kinailangan bang magpahinga sa paggamit ng Pills kapag matagal ng gumagamit nito?</ListItemHeader>
                            <p><strong>Hindi.</strong> Maraming pag-aaral ang nagsasaad na ang pangmatagalang paggamit ng pills ay nagtataglay ng maraming benepisyo sa kalusugan ng isang Pinay.</p>
                        </ListItem>
                        <ListItem>
                            <Image image={ getImage(data.faq5.childImageSharp) } altText="Mother breast feeding" />
                            <ListItemHeader>Nakakaapekto ba ang Pills sa nagpapasuso na ina at sa kanyang anak?</ListItemHeader>
                            <p>Inirerekomendang gumamit lamang ng Progestin-only pills. Hindi ito nakakaapekto sa dami at kalidad ng gatas ng ina at hindi naaapektuhan ang kalusugan ng baby.</p>
                        </ListItem>
                        <ListItem>
                            <Image image={ getImage(data.faq6.childImageSharp) } altText="endometrial cancer at ovarian cancer" />
                            <ListItemHeader>Ang Pills ay nagdudulot ba ng kanser?</ListItemHeader>
                            <p><strong>Hindi.</strong> Sa katunayan, ang pills ay nagbibigay ng proteksyon laban sa endometrial cancer at ovarian cancer.</p>
                        </ListItem>
                        <ListItem>
                            <Image image={ getImage(data.faq7.childImageSharp) } altText="Trash can" />
                            <ListItemHeader>Nagdudulot ba ng sakit ng ulo ang Pills?</ListItemHeader>
                            <p>May mga paunang reaksyon ang bawat katawan sa Pills.</p>
                            <UlList listStyleImage={data.check2.publicURL}>
                                <li>Ipinapayo na bigyan ang sarili ng hanggang tatlong buwan para makapag-adjust at maging hiyang dito.</li>
                                <li>Kapag hindi nasanay ang katawan sa takdang panahon, kumunsulta sa Doktor sa pagpapalit ng pills at patuloy na hanapin ang hiyang sa iyo.</li>
                            </UlList>
                        </ListItem>
                        <ListItem>
                            <Image image={ getImage(data.faq8.childImageSharp) } altText="Partners on bed" />
                            <ListItemHeader>Kailangan bang itapon ang buong banig ng Pills kapag nakaligtaang uminom ng higit sa isang Pill?</ListItemHeader>
                            <p><strong>Hindi.</strong> Tandaan lamang na kapag mahigit sa isang pill ang nakalimutan, kailangang mag back-up method tulad ng paggamit ng <Link to="/over-the-counter/#TRUST-section" className="text-blue-500">TRUST Condom</Link> at ubusin ang natitirang pills sa nakatakdang araw. Basahin ang product insert para magabayan sa tamang pag-inom kung may "Missed pills".</p>
                        </ListItem>
                        <ListItem>
                            <Image image={ getImage(data.faq9.childImageSharp) } altText="Female organ" />
                            <ListItemHeader>Nawawala ba ang gana sa pagtatalik kapag nag-Pills?</ListItemHeader>
                            <p>Bigyan ang sarili ng hanggang tatlong buwan para makapag-adjust at maging hiyang dito.</p>
                        </ListItem>
                        <ListItem>
                            <Image image={ getImage(data.faq10.childImageSharp) } altText="Woman" />
                            <ListItemHeader>Naiipon ba ang regla sa katawan habang gumagamit ng Pills or Injectable?</ListItemHeader>
                            <p><strong>Hindi.</strong> Lahat ng regla na dapat lumabas ay nailalabas habang umiinom ng pills o nainiksyunan ng injectable, kaya hindi ito naiipon sa katawan.</p>
                        </ListItem>
                        <ListItem>
                            <Image image={ getImage(data.faq11.childImageSharp) } altText="Pimples" />
                            <ListItemHeader>Ang Pills ay nakaka-pimples. Hindi.</ListItemHeader>
                            <p><strong>Hindi.</strong> May klase ng pills na nakakabawas ng pimples at nagbibigay ng magandang kutis.</p>
                        </ListItem>
                        <ListItem className="list-none">
                            <ListItemHeader>References:</ListItemHeader>
                            <ol className="pl-10 text-sm text-gray-500 list-decimal lg:text-lg">
                                <li>WHO Medical Eligibility Criteria for Contraception 5th Edition</li>
                                <li>WHO John Hopkins Bloomberg School of Public Health/Center for Communication Programs. Family Planning: A Global Handbook for Providers. 2011</li>
                                <li>WHO Family Planning : A Global Handbook for Providers 3rd Edition 2018</li>
                                <li>Department of Health FAQs on Family Planning</li>
                                <li>DOH Family Planning Printable Brochure as contributed by Health Promotion and Communication (Project from the help of USAID)</li>
                                <li>The Philippine Clinical Standards Manual on Family Planning 2014 Edition</li>
                                <li>Gollnick H, Albring M, Brill K. The effectiveness of oral cyproterone acetate in combination with ethinyl estradiol in an acne tarda of the facial type. Ann Endocrinal (Paris) 1999:60:157-166.</li>
                                <li>All visuals on DKT Health Inc. Reference files</li>
                            </ol>
                        </ListItem>
                    </ol>
                </div>                
            </section>
        </Layout>
    )
}

export default Faqs;


const ListContainer1 = ({title, listIconUrl, listTexts}) => (
    <FAQBoxContainer1>
        <h4 className="text-lg md:text-3xl text-header">
            { title }
        </h4>
        <FAQContent1 listStyleImage={ listIconUrl }>
            <ul className="text-md md:text-2xl">
                {listTexts && listTexts.map((item, i) => (
                    <li key={`ul-li-${i}`}>{ item }</li>
                ))}
            </ul>
        </FAQContent1>
    </FAQBoxContainer1>
)
const ListContainer2 = ({ title, listIconUrl, listTexts, image }) => (
    <FAQBoxContainer2>
        {image && (<GatsbyImage 
            className="float-right m-5 rounded-full lg:mr-20" 
            style={{ shapeOutside:'circle(80px at center)' }} 
            image={ image } 
            alt="FAQ Icon" />
        )}
        
        <h4 className="text-lg md:text-3xl text-header">
            { title }
        </h4>
        
        <FAQContent2 listStyleImage={ listIconUrl } withCounter={ listTexts.length > 1 } >
            <ol className="pt-2 text-md md:text-2xl">
                {listTexts && listTexts.map((list, idx) => (
                    <li key={`ol-li-${idx}`}>
                        {list.title && (<strong>{list.title}</strong>)}
                        <ul>
                            {list.texts && list.texts.map((text, i) => (
                                <li key={`sub-li-${i}`}>{text}</li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ol>
        </FAQContent2>
    </FAQBoxContainer2>
)
const Box1 = styled.div`
    position: relative;
    &:before,
    &:after {
        z-index:0;
        content:'';
        position: absolute;
        width: calc(100% - 20px);
        height: calc(100% - 10px);
    }
    &:before {
        left: 0;
        top: 0;
        border-top: solid 5px #a6d6ef;
        border-left: solid 5px #a6d6ef;
    }
    &:after {
        right: 0;
        bottom: 0;
        border-bottom: solid 5px #a6d6ef;
        border-right: solid 5px #a6d6ef;
    }
`
const FAQBoxHeader = styled.div`
    position: relative;
    padding: 1rem .6rem 2rem 1rem;
    width: 100%;
    color: white;
    margin: 0;
    background-color: ${props => props.bgColor};
    background-image: radial-gradient(circle at top right,rgba(255,255,255,.8), ${props => props.bgColor}, ${props => props.bgColor});

    & .text-header {
        text-shadow: 3px 2px ${props => props.textShadow};
    }
    
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left:0;
        height: 20px;
        width: 100%;
        background-color: ${props => props.bottomColor};
    }
`
const ResponsiveIcon = styled.div`
    position: relative;
    float: right;
    margin: 0.75rem;
    border-radius: 9999px;
    width: 100px;
    @media (min-width: ${tw.theme.screens.md}) {
        width: 167px;
    }
`
const Image = ({ image, altText }) => (
    <ResponsiveIcon>
        <GatsbyImage 
            className="h-full" 
            style={{ shapeOutside:'circle(80px at center)' }} 
            image={ image } 
            alt={ altText }
        />
    </ResponsiveIcon>
)

const FAQBoxContainer1 = styled.div`
    position: relative;
    padding: .7rem 0;
    margin-left: .3rem;
    margin-right: .3rem;
    @media (min-width: ${tw.theme.screens.lg}) {
        margin-left: 5rem;
        margin-right: 5rem;
    }
    & .text-header {
        position: relative;
        display: inline-block;
        color: white;
        padding: .2rem 1rem .2rem 1.2rem;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: #00abec;
        text-shadow: 1px 1px rgba(0,0,0,0.7);
    }
    & .text-header::after {
        position: absolute;
        content: '';
        left: 0;
        top: 100%;
        width: 0;
        height: 0;
        border-top: .7rem solid #005da4;
        border-left: 1rem solid transparent;
    }
`
const FAQContent1 = styled.div`
    position: relative;
    margin-left: 1rem;
    border-left: solid thin white;
    border-bottom: solid thin white;
    & ul {
        list-style-image: url(${props => props.listStyleImage ? props.listStyleImage:'circle'}) !important;
        padding-left: 2rem;
        padding-top: .4rem;
    }
    & ul li {
        padding: .3rem 0;
    }
`
const FAQBoxContainer2 = styled.div`
    position: relative;
    padding: .7rem 0;
    margin-left: .3rem;
    margin-right: .3rem;
    @media (min-width: ${tw.theme.screens.lg}) {
        margin-left: 5rem;
        margin-right: 5rem;
    }
    & .text-header {
        position: relative;
        display: inline-block;
        color: black;
        font-weight: bold;
        padding: .2rem 1.2rem .2rem 1.2rem;
        background-color: #f7941d;
        clip-path: polygon(0 0,100% 0,90% 100%,0 100%);
        text-shadow: 1px 1px white;
    }
`
const FAQContent2 = styled.div`
    position: relative;
    margin-left: 1rem;
    ${props => props.withCounter && css`
        & ol {
            list-style-type: upper-alpha;
            list-style-position: inside;
            counter-reset: list;
        }
        & ol > li {
            list-style: none;
        }
        & ol > li::before {
            content: counter(list, upper-alpha) ".) ";
            counter-increment: list;
        }
    `}
    & ol ul {
        list-style-image: url(${props => props.listStyleImage ? props.listStyleImage:'circle'}) !important;
        padding-left: 2rem;
        padding-top: .4rem;
    }
    & ol ul li {
        padding: .3rem .1rem;
    }
`
const ListItem = styled.li`
    padding: 1rem;
    width: 100%;
    &:nth-child(4n) {
        background-color: #bbe3d;
    }
    @media (min-width: ${tw.theme.screens.sm}) {
        width: 320px;
    }
    @media (min-width: ${tw.theme.screens.md}) {
        width: 360px;
    }
    @media (min-width: ${tw.theme.screens.lg}) {
        width: 460px;
    }
    @media (min-width: ${tw.theme.screens.xl}) {
        width: 540px;
    }
`
const ListItemHeader = ({ children }) => (<strong className="text-lg md:text-3xl text-header">{ children }</strong>)

const UlList = styled.ul`
    list-style-image: url(${props => props.listStyleImage ? props.listStyleImage:'circle'}) !important;
    padding-left: 2rem;
    padding-top: .4rem;
    & li {
        padding: .3rem 0;
    }
`

